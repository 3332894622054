body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.yellow {
  background-color: yellow;
  max-width: 120px;
  border: solid 1px red;
  font-weight: bold;
  padding: 5px;
  margin-right: 20px;
  align-content: center;
}

.error {
  font-weight: bold;
  color: red;
}